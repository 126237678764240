@charset "utf-8";
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

video::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

video::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
video::-webkit-media-controls-panel,
video::-webkit-media-controls-start-playback-button {
    display: none !important;
}
/*html {
}*/

h1,h2,h3{
  font-weight: normal;
  font-size: 1em;
}
body{
  background-color: #FFFFFF;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  touch-action: none;
}
html, body{
    margin: 0;
    padding: 0;
    width: 100% !important;
    font-family: sans-serif;
}

body::-webkit-scrollbar-track, select::-webkit-scrollbar-track, .Scrollable::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0);
  background-color: #ffffff;
}

body::-webkit-scrollbar, select::-webkit-scrollbar, .Scrollable::-webkit-scrollbar
{
  width: 15px;
  background-color: #ffffff;
}

body::-webkit-scrollbar-thumb, select::-webkit-scrollbar-thumb, .Scrollable::-webkit-scrollbar-thumb
{
  background-color: #cccccc;
}
.Scrollable2::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0);
    background-color: #ffffff;
}

.Scrollable2::-webkit-scrollbar
{
    width: 7px;
    background-color: #ffffff;
}

.Scrollable2::-webkit-scrollbar-thumb
{
    background-color: #cccccc;
}

.ScrollableTransparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.ScrollableTransparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.ScrollableTransparent::-webkit-scrollbar-thumb
{
    background-color: #000000;
}
.scrollAutomatic::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px transparent;
    background-color: transparent;
}

.scrollAutomatic::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: transparent;
}

.scrollAutomatic::-webkit-scrollbar-thumb
{
    background-color: transparent;
}
.scrollAutomatic{
  overflow-y: auto;
  height: 100%;
}
.Centered{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.Scrollable, .Scrollable2{
  overflow-y: scroll;
}
.Button{
  border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border: 0px solid #000000;
  color: #ffffff;
  cursor: pointer;
  transition: .5s;
  padding: 5px 15px 5px 15px;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}
textarea:focus, input:focus,button ,.button, select{
    outline: none;
}
p{
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
/*p:hover{
  overflow: visible;
}*/
p, input, div{
  -webkit-tap-highlight-color: transparent;
}
a{
  text-decoration: none;
}
/*.transparent_link{
  
}*/
.rainbow {
   /* Chrome, Safari, Opera */
  -webkit-animation: rainbow 3s infinite; 
  
  /* Internet Explorer */
  -ms-animation: rainbow 3s infinite;
  
  /* Standar Syntax */
  animation: rainbow 3s infinite; 
}

/* Chrome, Safari, Opera */
@-webkit-keyframes rainbow{
  0%{color: #375379;}  
  25%{color: #B92014;} 
  50%{color: #375379;}  
  75%{color: #B92014;} 
  100%{color: #375379;}
}
/* Internet Explorer */
@-ms-keyframes rainbow{
   0%{color: #375379;}  
  25%{color: #B92014;} 
  50%{color: #375379;}  
  75%{color: #B92014;} 
  100%{color: #375379;} 
}

/* Standar Syntax */
@keyframes rainbow{
   0%{color: #375379;}  
  25%{color: #B92014;} 
  50%{color: #375379;}  
  75%{color: #B92014;} 
  100%{color: #375379;}
}
.DesactiveStyle{
  opacity: .6;
}
.BackGroundTransparent{
  background-color: transparent !important;
}
/*----------------------------*/
/*----------------------------*/
/*----------------------------*/
.DropDownList{
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  list-style: none;
}
.DropDownList .DropDownListItem{
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #cccccc;
}
.DropDownList .DropDownListItem .DropDownItemHeader{
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
}
.DropDownList .DropDownListItem .DropDownItemHeader p{
  display: inline-block;
  vertical-align: middle;
}
.DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderClicked{
  cursor: pointer;
  transition: .5s;
}
.DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderClicked:hover,
.DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderClicked:focus{
  transform: scale(1.1);
}
.DropDownList .DropDownListItem .DropDownItemHeader .DropDown{
  color: #59907A;
}
.DropDownList .DropDownListItem .DropDownItemHeader .DropedDown{
  color: #C60000;
}
.DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderTitle{
  color: #444444;
}
.DropDownList .DropDownListItem .DropDownItemContainer{
  margin: 0;
  padding: 0;
  display: block;
  display: none;
  background: #f4f4f4;
}
/*------------------------------*/
.ImageContainer{
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  text-align: center;
  background-color: #999999;
  cursor: pointer;
  transition: .5s;
  
}
.ImageContainer:hover{
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.ImageContainer .ImageText{
  margin: 0;
  padding: 0;
  display: block;
  color: #ffffff;
}
.ImageContainer .image_preview{
  width: 100%;
}
/*------------------------------*/
/*------------------------------*/
/*------------------------------*/
#MessageContainer{
  position: fixed;
  display: block;
  display: none;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #666666;
  background-color: rgba(255,255,255,.99);
  z-index: 200;
}
#MessageContainer div{
  text-align: center;
  width: 85%;
}
#MessageContainer div p{
  margin: auto;
  padding: 0;
  display: block;
  margin-bottom: 1%;
}
#MessageContainer div button{
  color: #ffffff;
  background-color: rgba(109,145,124,1);
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  -webkit-box-shadow: 10px 10px 31px -10px rgba(255,255,255,1);
  -moz-box-shadow: 10px 10px 31px -10px rgba(255,255,255,1);
  box-shadow: 10px 10px 31px -10px rgba(255,255,255,1);
  border: 0px solid #000000;
  font-size: 2em;
  padding-left: 2%;
  padding-right: 2%;
  cursor: pointer;
  transition: .3s;
}
#MessageContainer div button:hover,
#MessageContainer div button:focus{
  background-color: rgba(109,145,124,.8);
}
#LoadContainer{
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,1);
}
#LoadContainer #LoadSubContainer{
  margin: 0;
  padding: 0;
  display: block;
}
#LoadContainer #LoadSubContainer img,#LoadContainer #LoadSubContainer p{
  margin: auto;
  padding: 0;
  display: block;
  text-align: center;
}
#LoadContainer #LoadSubContainer #LoadText{
  color: #666666;
}
/*---------------------*/
/*---------------------*/
/*---------------------*/
.IncreaseDecrease{
  transition: .1s;
     /* Chrome, Safari, Opera */
  -webkit-animation: increase_decrease .7s infinite; 
  
  /* Internet Explorer */
  -ms-animation: increase_decrease .7s infinite;
  
  /* Standar Syntax */
  animation: increase_decrease .7s infinite; 
}

/* Chrome, Safari, Opera */
@-webkit-keyframes increase_decrease{
  0%{transform: scale(.9);}  
  5%{transform: scale(.91);} 
  10%{transform: scale(.92);}  
  15%{transform: scale(.93);} 
  20%{transform: scale(.94);}
  25%{transform: scale(.95);}  
  30%{transform: scale(.96);} 
  35%{transform: scale(.97);}  
  40%{transform: scale(.98);} 
  45%{transform: scale(.99);}
  50%{transform: scale(1);}
  55%{transform: scale(.99);}  
  60%{transform: scale(.98);} 
  65%{transform: scale(.97);}  
  70%{transform: scale(.96);} 
  75%{transform: scale(.95);}
  80%{transform: scale(.94);}  
  85%{transform: scale(.93);} 
  90%{transform: scale(.92);}  
  95%{transform: scale(.91);} 
  100%{transform: scale(.9);}
}
/* Internet Explorer */
@-ms-keyframes increase_decrease{
  0%{transform: scale(.9);}  
  10%{transform: scale(.92);} 
  20%{transform: scale(.94);}  
  30%{transform: scale(.96);} 
  40%{transform: scale(.98);}
  50%{transform: scale(1);}  
  60%{transform: scale(.98);} 
  70%{transform: scale(.96);}  
  80%{transform: scale(.94);} 
  90%{transform: scale(.92);}
  100%{transform: scale(.9);}
}

/* Standar Syntax */
@keyframes increase_decrease{
  0%{transform: scale(.9);}  
  10%{transform: scale(.92);} 
  20%{transform: scale(.94);}  
  30%{transform: scale(.96);} 
  40%{transform: scale(.98);}
  50%{transform: scale(1);}  
  60%{transform: scale(.98);} 
  70%{transform: scale(.96);}  
  80%{transform: scale(.94);} 
  90%{transform: scale(.92);}
  100%{transform: scale(.9);}
}


@media screen and (min-width: 701px) {
  .DropDownList .DropDownListItem{
    padding-top: .5%;
    padding-bottom: .5%;
  }
  .DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderClicked{
    font-size: 3.5em;
    margin-right: 1%;
  }
  .DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderTitle{
    font-size: 2em;
  }
  .DropDownList .DropDownListItem .DropDownItemContainer{
    width: 90%;
    margin-left: 4%;
    padding: 1%;
    padding-bottom: 5%;
  }
  /*------------------------------*/
  .ImageContainer{
    width: 85%;
    padding-bottom: 2%;
    padding-top: 2%;
    margin-bottom: 2%;
  }
  /*------------------------------*/
  /*------------------------------*/
  /*------------------------------*/
  #MessageContainer div p{
    font-size: 4em;
  }
  #LoadContainer #LoadSubContainer{
    width: 50%;
  }
  #LoadContainer #LoadSubContainer #LogoLoad{
    width: 200px;
    padding-bottom: 5px;
  }
  #LoadContainer #LoadSubContainer #LoadLoad{
    width: 25px;
    margin-top: 0%;
  }
}

@media screen and (max-width: 700px) {
  .DropDownList .DropDownListItem{
    padding-top: .5%;
    padding-bottom: .5%;
  }
  .DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderClicked{
    font-size: 2.5em;
    margin-right: 3%;
  }
  .DropDownList .DropDownListItem .DropDownItemHeader .DropDownHeaderTitle{
    font-size: 2em;
    width: 80%;
  }
  .DropDownList .DropDownListItem .DropDownItemContainer{
    width: 90%;
    margin-left: 4%;
    padding: 1%;
    padding-bottom: 5%;
  }
  /*------------------------------*/
  /*------------------------------*/
  .ImageContainer{
    width: 85%;
    padding-bottom: 2%;
    padding-top: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  /*------------------------------*/
  /*------------------------------*/
  /*------------------------------*/
  #MessageContainer div p{
    font-size: 2em;
  }
  #LoadContainer #LoadSubContainer #LogoLoad{
    width: 100px;
  }
  #LoadContainer #LoadSubContainer #LoadLoad{
    width: 30px;
    margin-top: 7px;
  }
}
